import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "News Archive & insights - Bartlett & Grippe LLC",
    description:
      "Looking for the latest news and updates on personal injury law? Stay informed with Bartlett & Grippe, Connecticut's top personal injury law firm.",
    heroH1: "News Archive",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Bartlett & Grippe in the News</h2>
            <h3>
              How Cheshire Attorney Secured $225,000 Settlement for Woman’s
              Wrist Injuries
            </h3>
            <p className="mb-8">
              <strong>
                Plaintiffs counsel Frank Bartlett Jr. tied his client’s jobs to
                her injuries in a two-car crash, and was able to leverage a
                settlement for her of $225,000.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | December 02, 2020
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                src="../../images/top-rated-ct-accident-attorney.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Blue vehicle after an automobile accident"
              />
            </div>
            <p className="mb-8">
              <strong>
                Tammara McMahon-Schriefer was injured after this Subaru Outback
                collided with another vehicle. She settled her case for
                $225,000. Courtesy photo
              </strong>
            </p>
            <p className="mb-8">
              It was Tammara McMahon-Schriefer’s livelihood as both an
              accountant and gardener that swayed the defense to give her
              $225,000 from a two-car accident in which she needed two surgeries
              on her left wrist, her lawyer said.
            </p>
            <p className="mb-8">
              Plaintiffs counsel and Cheshire-based practitioner Frank Bartlett
              Jr. stressed the fact that McMahon-Schriefer used that wrist for
              her accounting job and her gardening. The accident, he said,
              diminished her income.
            </p>
            <p className="mb-8">
              “I tried to make the correlation to the insurance adjuster between
              someone who just plays the violin and someone who is a concert
              violinist. In our case, gardening was not just a hobby but
              something my client received a degree in and specialized in. It
              was more significant for her; that was our strategy,” Bartlett
              said Wednesday.
            </p>
            <p className="mb-8">
              Bartlett continued: “The wrist fracture prevented her from doing
              heavy lifting or weeding or digging or anything associated with
              gardening. This was her livelihood.”
            </p>
            <p className="mb-8">
              When the insurance adjuster offered $163,000 of the $250,000
              insurance policy, Bartlett knew he had an ace in the hole with his
              client’s injuries to her left wrist, because that is what she used
              to make her living as an accountant and a professional gardener.
            </p>
            <p className="mb-8">
              Bartlett held out for more. The defense agreed to pay the
              plaintiff $225,000 on Nov. 20. The carrier was MAPFRE Insurance
              and the check was received Wednesday morning.
            </p>
            <p className="mb-8">
              Defense counsel Angela Ciottone of Mazzocca & Associates in
              Westborough, Massachusetts, said Wednesday: “I do feel the
              settlement was fair given the plaintiff’s two surgeries, given her
              occupation and her life expectancy.”
            </p>
            <p className="mb-8">
              According to Bartlett and the March 6 lawsuit filed in Torrington
              Superior Court, McMahon-Schriefer, a 59-year-old Brookfield
              resident, T-boned a vehicle Kenneth Allen Jr. was driving. Police
              said Allen was at fault because he pulled in front of the
              plaintiff’s vehicle and McMahon-Schriefer wasn’t able to stop in
              time. Allen was given an infraction for failure to yield the right
              of way. The accident occurred in March 2018 in Roxbury.
            </p>
            <p className="mb-8">
              Bartlett said that when first presented with the offer to settle
              for $163,000, he “kept the lines of communication open, rather
              than saying it was a very low number that would prevent us from
              continuing the negotiations.”
            </p>
            <p className="mb-8">
              Bartlett continued: “We highlighted the impact this had on my
              client’s life and worked with them to increase their offer to
              something we could negotiate with.”
            </p>
            <p className="mb-8">
              In addition to her left wrist, the plaintiff also had injuries to
              her neck; upper back; head; chest; and an allergic reaction to the
              surgical hardware that was put in her left wrist. A second surgery
              was required to remove that hardware, Bartlett said.
            </p>
            <p className="mb-8">
              Almost three years after the accident, McMahon-Schriefer still has
              “pain in her left wrist, limitation in her range of motion, and
              she can’t lift heavy objects,” Bartlett said.
            </p>
            <p className="mb-8">
              McMahon-Schriefer incurred about $94,000 in medical expenses.
            </p>
            <p className="mb-8">
              In court pleadings soon after the accident, the defense wrote
              McMahon-Schriefer was negligent for her own accident in that she
              “failed to keep a proper and reasonable lookout for other motor
              vehicles upon the highway.”
            </p>
            <p className="mb-8">
              Article courtesy of the Connecticut Law Tribune
            </p>

            <h3>The Story Behind the Story</h3>
            <p className="mb-8">
              <strong>
                An Interesting turn of events led to the story above and
                contains a very valuable lesson for others.
              </strong>
            </p>
            <p className="mb-8">
              Tammara came to us seeking advice after being involved in a car
              accident. She thought she didn’t need a lawyer because the police
              found the other driver at fault and her injuries were clear, she
              had broken her wrist and needed surgery. We went over her options
              and explained the information we would need to support her claims
              and present her case to the adjuster. She decided to try to settle
              her case on her own.
            </p>
            <p className="mb-8">
              Over the next two years she completed all of the forms and
              authorizations the insurance company asked her to fill out and
              provided the adjuster with receipts she had collected along with a
              thorough spreadsheet documenting her injuries and the expenses she
              had incurred. Two weeks before the statute of limitations expired
              she came to us again. The insurance company’s offer? ZERO. That’s
              right, the insurance company didn’t offer her a penny on a case
              where their driver pulled out into traffic, totaling her car and
              leaving her with a fractured wrist and other injuries.{" "}
            </p>
            <p className="mb-8">
              We immediately filed a suit on her behalf and began compiling the
              documentation we had discussed in our initial consultation. We
              worked with her doctors and obtained the causation and prognosis
              opinions we needed to establish the nature and extent of her
              injuries and the fact that the limitations she was experiencing
              would continue to affect her for the rest of her life. The
              insurance company’s response? An initial offer of $163,000. While
              this is a long way from zero, it wasn’t a fair settlement based on
              how the accident affected our client’s life. Through continued
              negotiations we were able to address the insurance company’s
              concerns and showed how we would present our case to the jury,
              thereby increasing the insurance company’s valuation of the case.
              Ultimately, the insurance company offered $225,000, a value our
              client was very happy with.
            </p>
            <p className="mb-8">
              This is very common, and unfortunately many people just accept a
              low offer or an insurance company’s denial of liability because
              they believe that the insurance company is “like a good neighbor”
              or “you’re in good hands” so what they are saying must be true and
              what they are offering must be fair. Always remember, it’s the
              insurance company’s job to pay the least amount possible. It’s
              your attorney’s job to see that you are fully compensated for all
              of the harms and losses you sustain in an accident.
            </p>
            <p className="mb-8">
              I started my career many years ago at a firm where I represented
              the insurance companies. I saw firsthand how these huge companies
              operate. At Bartlett & Grippe we only represent the injured and we
              fight to get every client a fair and just result.
            </p>
          </div>
          <div className="mb-16">
            <h3>Hiring Meteorologist Helped Turn Around Snow Slip-and-Fall</h3>
            <p className="mb-8">
              <strong>
                Plaintiff counsel Frank Bartlett Jr. secured the settlement for
                a man who fell on accumulated ice and snow outside his condo
                complex.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | October 26, 2020
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                src="../../images/accumulated-snow.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Photo of the location of a slip and fall injury due to snow and ice"
              />
            </div>
            <p className="mb-8">
              <strong>
                New Haven Resident William Clark Jr. fell on an accumulation of
                ice and snow at his apartment complex. He recently won a
                $400,000 settlement. Courtesy photo.
              </strong>
            </p>
            <p className="mb-8">
              At first look, it appeared plaintiff’s attorney Frank Bartlett Jr.
              had two strikes against him in a case involving a slip-and-fall
              during a snowstorm, because Connecticut’s ongoing-storm doctrine
              allowing property managers leeway had come into play. Bartlett, a
              principal at Bartlett & Grippe in Cheshire, represented New Haven
              condominium complex tenant William Clark Jr., who fell on
              accumulated ice and snow on his rear stoop during one of many
              December 2017 snowstorms. Clark fractured his left ankle and
              during his recovery sustained a blood clot that traveled to his
              lungs.
            </p>
            <p className="mb-8">
              The problem for Bartlett was twofold: There was the doctrine he
              had to contend with and there was additional defense pushback from
              the condo owners who said Clark was responsible for clearing condo
              steps from ice and snow. Under state law, the doctrine allows
              property managers “reasonable” time after the end of a storm to
              clear ice and snow. “That was an immediate concern. There is no
              liability for snow that falls during the storm,” Bartlett said.
              While some attorneys might have thrown in the towel, Bartlett
              decided to retain a meteorologist to review the records of the
              recent weather in the area.
            </p>
            <p className="mb-8">
              That hiring gave Bartlett an edge, one that allowed him to secure
              a $400,000 settlement for his client on Oct. 16.“ The
              meteorologist was able to tell us there were three snowstorms
              leading up to this event and the property manager hadn’t cleared
              the snow from those storms. There would have been a layer of ice
              and then a layer of snow under the freshly fallen snow and that
              really lined up with our client’s testimony,” Bartlett said
              Monday. Bartlett continued: “The property owners didn’t clear up
              after each storm like they were supposed to. ”Then Bartlett had to
              deal with the contention from defendant condominium association
              owner Northside Mews Association Inc. that it was Clark’s
              responsibility to clear his own stoop.
            </p>
            <p className="mb-8">
              “We did some digging and got a hold of the condo bylaws, which
              said condo owners have the responsibility to clear snow from
              balconies and porches. The agreement was silent on the condo
              owner’s responsibility to clear condo steps. It said nothing on it
              and so it reverted back to the responsibility of the condo
              association to clean the steps with salt and sand,” Bartlett said.
              Bartlett said the tip for fellow personal injury attorneys is to
              keep your eye on the ball. “Although, at first blush this seemed
              like an almost impossible liability case, by doing our homework
              and really researching this, we were able to show this was really
              a strong liability case,” Bartlett said.
            </p>
            <p className="mb-8">
              The lawsuit in Clark v. Northside Mews Association and Evergreen
              Property Management was filed in November 2019. The defendants are
              the condo association and the property management company,
              respectively. Bartlett said his 33-year-old client “still has
              ongoing ankle pain. He was an avid bowler before the accident but
              now can’t bowl as frequently as he used to.” Clark, a security
              guard for an industrial complex, incurred $229,000 in medical
              costs, Bartlett said.
            </p>
            <p className="mb-8">
              The case was resolved following mediation between Judge James
              Abrams, Bartlett and defense counsel Matthew Conway of Conway
              Stoughton in Hartford. Conway didn’t respond to a request for
              comment Monday. Bartlett said the $400,000 settlement was fair.
              “We did have a client who had a favorable recovery and that could
              have been beneficial to the defense at trial,” Bartlett said.
            </p>
            <p className="mb-8">
              In court pleadings, the defense said Clark was responsible for his
              own actions. The papers said Clark “failed to be watchful of his
              surroundings” and “failed to utilize an exterior overhead light
              available to him that could illuminate the rear stoop and steps
              appurtenant to 245 Barnes Avenue.”
            </p>
            <p className="mb-8">
              Article courtesy of the Connecticut Law Tribune
            </p>
          </div>
          <div className="mb-16">
            <h3>
              2 Accidents, 1 Chance to Attribute Injury: How a Cheshire Lawyer
              Secured a Settlement
            </h3>
            <p className="mb-8">
              <strong>
                Cheshire attorney Frank Bartlett has secured a six-figure
                settlement for a client who was injured twice in car accidents
                20 months apart.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | September 04, 2020
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                src="../../images/car-accident-attorney.jpg"
                formats={["auto", "webp", "avif"]}
                alt="License plate of vehicle involved in automobile accident"
              />
            </div>
            <p className="mb-8">
              <strong>
                Dana Loubriel was injured when a motorist rear-ended her Kia
                Sorento in August 2015. She was also rear-ended 20 months later.
                She recently received a $113,500 settlement from both accidents,
                the cases of which were consolidated. Courtesy photo
              </strong>
            </p>
            <p className="mb-8">
              Plaintiffs attorney Frank Bartlett’s client Dana Loubriel was
              rear-ended in two separate car accidents 20 months apart and he
              had to face the dilemma of going to battle against two attorneys
              who were pointing the fingers at each other for the plaintiff’s
              injuries.Bartlett, of Bartlett & Grippe, LLC in Cheshire, said the
              attorney representing the insurance carrier in the August 2015
              accident was blaming Loubriel’s head, neck and back injuries on
              the driver who rear-ended her in the second accident, which
              occurred in April 2017. And to complicate matters even further,
              Bartlett said, the attorneys representing the insurance carrier in
              the April 2017 accident were saying the main injuries Loubriel
              faced were tied to the first accident.
            </p>
            <p className="mb-8">
              Bartlett said Loubriel sustained head, neck and back injuries in
              both accidents. The injuries were exacerbated in the second
              accident, he said. “Our biggest obstacle was showing which
              accidents caused which injuries,” Bartlett said Friday.
            </p>
            <p className="mb-8">
              In an effort to protect their clients from liability, Bartlett
              said, both defense attorneys were adamant in blaming Loubriel’s
              injuries on the other party. All sides agreed to consolidate the
              cases, Bartlett said.
            </p>
            <p className="mb-8">
              Bartlett ended up getting $113,500 for his client Aug. 27, in
              part, he said, in using the defendant’s expert in the second
              accident to his benefit.“There was an orthopedic expert for the
              defendant in the second car crash saying that my client’s ongoing
              medical issues were from the first accident,” Bartlett said. “We
              deposed that expert who did a video deposition so we could use at
              trial if we had to. The defense expert was saying the plaintiff’s
              injuries were permanent and caused by the first accident.”“It all
              worked well for us,” Bartlett said. “I had not only my expert who
              was saying my client was injured in both accidents, but I had the
              defense expert for the second accident saying she had ongoing
              medical issues from the first accident.”That tactic of using a
              defense expert to his benefit, Bartlett said, enabled him to get
              the defense attorney in the first accident to more than double his
              offer.
            </p>
            <p className="mb-8">
              Loubriel’s deposition testimony, Bartlett said, also helped. “She
              made a sympathetic witness,” Bartlett said. “She was also pregnant
              when the second accident happened and that is something a jury
              would have latched on to.” The accident didn’t affect the
              pregnancy, Bartlett said.
            </p>
            <p className="mb-8">
              In the end, Loubriel received $55,000 for the first accident and
              $58,500 for the second accident. The total package was $113,500.
              Loubriel, a 40-year-old Meriden resident, never had surgery for
              her injuries, her attorney said. Bartlett said his client had both
              chiropractic services and saw an orthopedic doctor.
            </p>
            <p className="mb-8">
              Representing the Allstate Corp. in the first accident was attorney
              Courtney Brenton with the Law Offices of Mark S. Gilcreast in
              Hamden. She didn’t respond to a request for comment
              Friday.Representing The Travelers Cos. in the second accident was
              attorney Jeffrey McDonald with Hassett & George in Simsbury.
              McDonald also didn’t respond to a request for comment.In court
              pleadings in both rear-end accidents, the defense left it up to
              the plaintiffs to prove their case.
            </p>
            <p className="mb-8">
              Article courtesy of The Connecticut Law Tribune.
            </p>
          </div>
          <div className="mb-16">
            <h3>
              Overcoming Suspicion, Cheshire Lawyer Clinches Settlement for Man
              Who Sued Girlfriend After Falling on Ice
            </h3>
            <p className="mb-8">
              <strong>
                Cheshire plaintiffs counsel Frank Bartlett helped secure a
                $250,000 settlement for his client, who sued his girlfriend,
                after falling on accumulated ice in her driveway.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | July 01, 2020
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                src="../../images/Davanand-Shiwgobin-Article-202007011201.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Surveilance photo of slip and fall injury due to snow and ice"
              />
            </div>
            <p className="mb-8">
              <strong>
                Davanand Shiwgobin, who fell on ice accumulation on a driveway
                on his girlfriend’s property, recently settled the case for
                $250,000.
              </strong>
            </p>
            <p className="mb-8">
              When plaintiff Davanand Shiwgobin sued his girlfriend for falling
              on accumulated ice on her driveway, the defense team thought the
              fix was in. Defense counsel took the view, according to plaintiffs
              counsel Frank Bartlett, that Shiwgobin and then-girlfriend Nirmala
              Budhai had colluded to fake the slip and fall and have it taped to
              collect on the insurance policy.
            </p>
            <p className="mb-8">
              So Bartlett, owner of Bartlett & Grippe in Cheshire, worked to
              prove otherwise. He built a case to show Shiwgobin had, in fact,
              fallen and injured himself. “Initially, I thought this would be
              your typical open-and-shut, slip-and-fall case,” Bartlett said
              Wednesday. “The defense made the claim that the girlfriend had
              just put in the surveillance camera … to capture the fall.”
            </p>
            <p className="mb-8">
              But Bartlett was ready to show that Budhai’s surveillance video,
              which captured the fall, had been installed years earlier.
            </p>
            <p className="mb-8">
              <strong>Building the case</strong>
            </p>
            <p className="mb-8">
              Shiwgobin fell in February 2018, according to the January 2019
              lawsuit filed in New Britain Superior Court. Budhai had asked him
              to take out the trash. When he fell, he injured his lower back,
              left leg and left foot, the lawsuit says.The case settled for
              $250,000 with Budhai’s homeowner’s policy, and Bartlett has a case
              pending aganst the two owners of the property.The attorney said he
              needed documentation to prove there was no collusion—lots of
              documentation.“There were questions from the defense on whether or
              not the plaintiff had lived with the defendant. He did not,”
              Bartlett said. “So we went to get the records from the Department
              of Motor Vehicles and bank records to show he owned a house in
              Waterbury. We also obtained the services of a meteorologist, who
              said based on a review of weather records, that the ice had
              existed in the driveway for at least 24 to 48 hours before the
              fall. There was a lot of leg work we did to dispel the collusion
              claims.
            </p>
            <p className="mb-8">
              ”The defense team had offered $50,000 to settle the matter;
              Bartlett said no. The defense, though, after faced with the bank
              and DMV records and, especially the weather records, did agree to
              pay $250,000 of the $300,000 policy limit.
            </p>
            <p className="mb-8">
              Bartlett said. Shiwgobin, 51, had to leave his job working for a
              Macy’s distribution center in Cheshire after the fall, Bartlett
              said. He incurred about $88,000 in medical expenses. Bartlett said
              his client had surgery to his back, which, he said, left Shiwgobin
              with pain and decreased range of motion. Shiwgobin, Bartlett said,
              also has a condition known as “drop foot” because of the fall.
            </p>
            <p className="mb-8">
              Representing CSAA Affinity Insurance Co., Budhai’s insurance
              carrier, were Joseph Andriola and Sean Caruthers, both with
              Neubert, Pepe & Monteith in New Haven. Neither attorney responded
              to a request for comment Wednesday. But in court pleadings, the
              defense put the blame on the plaintiff. They alleged Shiwgobin
              failed to “keep and maintain a reasonable and proper lookout” and
              “failed to make reasonable and proper use of his senses and
              faculties.”
            </p>
            <p className="mb-8">Courtesy of the Connecticut Law Tribune</p>
          </div>
          <div className="mb-16">
            <h3>
              Consolidating Suits From Accident-Prone Plaintiff Pays Off for
              Cheshire Attorney
            </h3>
            <p className="mb-8">
              <strong>
                Plaintiffs attorney Frank Bartlett secured a $350,000 settlement
                for a client who was injured in two car crashes.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | February 27, 2020
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                width="478"
                src="../../images/235-Church-Street-view-New-Haven-Court.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Photo of New Haven Superior Court"
              />
            </div>

            <p className="mb-8">
              <strong>New Haven Superior Court. </strong>
            </p>
            <p className="mb-8">
              Representing a New Haven man whose pleadings showed he had the
              misfortune of being in two car crashes in five months, plaintiffs
              attorney Frank Bartlett had a decision to make: File a
              consolidated lawsuit or two complaints. The attorney, owner of
              Cheshire-based Bartlett & Grippe, made the decision to file one
              lawsuit on behalf of 29-year-old client Joel Contreras-Arteaga. He
              figured the move would allow him to bring both defendants to the
              negotiating table, and strengthen the odds of gaining a favorable
              result for his client.
            </p>
            <p className="mb-8">
              “There I was, with the attorney for the driver in the second
              accident, putting pressure on the attorney for the driver in the
              first accident,” Bartlett said. “You would not have had that
              dynamic if there was two lawsuits.” In the end, the defendants’
              insurers made a total payout of $350,000.
            </p>
            <p className="mb-8">
              “In filing one lawsuit, you look more credible in the eyes of the
              insurance companies,” Bartlett said Thursday. “In this way, they
              know you are not trying to hide another accident or injuries. At
              the end of the day, you also feel like you are getting a better
              result.
            </p>
            <p className="mb-8">
              The lawsuit was filed in September 2018 in New Haven Superior
              Court. Bartlett said his client had a spell of bad luck within a
              five-month period, and that Contreras-Arteaga had not caused the
              crashes. In the first instance, there was a collision on
              Interstate 95, between Contreras-Arteaga’s vehicle and one that
              George Cruz was driving. Police didn’t ticket either driver, and
              said it wasn’t clear who was at fault. Bartlett said that crash
              required his client to undergo back surgery.
            </p>
            <p className="mb-8">
              The second collision was a rear-ender in March 2017 in North
              Branford, in which defendant driver Julia Dewitt was cited for
              following too closely, Bartlett said. The case was resolved Feb.
              10. NJM Insurance Group, the insurance carrier for Cruz, paid
              $300,000 toward the settlement. Liberty Mutual Insurance Co., the
              insurer for Dewitt, paid $50,000. Cruz’s attorney, Don Swift of
              New Haven-based Lynch, Traub, Keefe & Errante, did not respond to
              a request for comment Thursday. Representing Dewitt was Jesalyn
              Cole with the Law Offices of Meehan, Roberts, Turret & Rosenbaum
              in Wallingford.
            </p>
            <p className="mb-8">
              Cole did not respond to a request for comment. Both defendants
              denied liability, leaving the plaintiff’s side to prove its case.
            </p>
            <p className="mb-8">Courtesy of the Connecticut Law Tribune</p>
          </div>
          <div className="mb-16">
            <h3>
              Cheshire Lawyer Clinches Settlement for Client With Preexisting
              Conditions
            </h3>
            <p className="mb-8">
              <strong>
                Cheshire-based attorney Frank Bartlett Jr. helped secure
                $102,000 for plaintiff Guy Beaudoin, who sustained injuries in a
                three-vehicle collision.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | February 05, 2020
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                width="478"
                src="../../images/car-accident-attorney-Frank-Bartlett.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Wrecked vehicle after automobile accident"
              />
            </div>

            <p className="mb-8">
              <strong>
                Guy Beaudoin reinjured his left shoulder following a
                three-vehicle accident in which his car was struck from behind
                by another vehicle, thus forcing the Beaudoin car to go into the
                rear of a school bus. Courtesy photo
              </strong>
            </p>
            <p className="mb-8">
              A Cheshire solo practitioner fought back claims from an insurance
              adjuster, who claimed the plaintiff would have had rotator cuff
              surgery anyway despite reinjuring his left shoulder in a
              three-vehicle crash. Plaintiff counsel Frank Bartlett Jr. overcame
              that defense argument to secure a $102,000 settlement.
            </p>
            <p className="mb-8">
              His client, Guy Beaudoin, a 58-year-old Stafford resident, was
              injured when he stopped for a school bus in Stafford in December
              2017. Beaudoin’s Subaru Legacy Outback hit a school bus after the
              Subaru Forester that Eileen Faas-Landry was driving rear-ended it.
              The impact, Bartlett and the police report said, was so strong
              that Beaudoin’s vehicle was pushed underneath the rear bumper of
              the school bus. Faas-Landry was issued an infraction for failure
              to travel at a safe distance apart. The case was settled with a
              Liberty Mutual Insurance Co. adjuster and no lawsuit was ever
              filed.
            </p>
            <p className="mb-8">
              The adjuster, Bartlett said, tried to argue that Beaudoin was
              going to have revision surgery anyway on his shoulder, due to a
              shoulder injury in the workplace in 2007. Therefore, Bartlett
              said, adjuster Joe Gustavsen maintained any settlement offer for
              Beaudoin should be minimal. “They argued that because of the
              initial rotator cuff repair in his left shoulder 12 years prior,
              that there was a normal progression for another surgery for
              ongoing pain,” Bartlett said. “We argued that he would not have
              needed surgery if not for the car accident. We showed that by
              working with his doctor. That doctor said the need for surgery was
              associated with this accident as opposed to a normal degeneration
              following the initial surgery.” Gustavsen did not respond to a
              request for comment Wednesday, and Faas-Landry did not have
              counsel.
            </p>
            <p className="mb-8">
              Beaudoin, who incurred $45,000 in medical expenses, also suffered
              neck and back pain following the car crash, Bartlett said. The
              defense’s initial offer, Bartlett said, was for $77,000. Bartlett
              said his initial demand was for $150,000. The case was settled
              Jan. 14 and Liberty Mutual disbursed the $102,000 on Jan. 17. A
              landscaper, Beaudoin missed four weeks of work following his
              January 2019 surgery, Bartlett said. Beaudoin, Bartlett said, “had
              some limited range of motion in his left shoulder following the
              surgery.”
            </p>
            <p className="mb-8">Courtesy of the Connecticut Law Tribune</p>
          </div>
          <div className="mb-16">
            <h3>
              LAWYER SIDESTEPS MUNICIPAL PROTECTIONS TO SUE GREENWICH OVER MAN’S
              DROWNING
            </h3>
            <p className="mb-8">
              <strong>
                Attorney Frank Bartlett has settled the case of a New Jersey man
                who drowned on property owned by the town of Greenwich for
                $600,000.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | December 09, 2019
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                width="478"
                src="../../images/Conroy.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Van being pulled from Cos Cob Town Marina afer drowning accident"
              />
            </div>

            <p className="mb-8">
              <strong>
                New Jersey resident Patrick Conroy drowned in this van after his
                vehicle left the travel portion of the parking lot of the Cos
                Cob Town Marina and entered the water. The town of Greenwich
                owns the marina and recently agreed to settle the case for
                $600,000. Courtesy photo.
              </strong>
            </p>
            <p className="mb-8">
              A Cheshire attorney has secured a $600,000 settlement for the
              estate of an 80-year-old New Jersey resident who drowned after
              accidentally driving his van off a parking lot and boat ramp known
              as the Cos Cob Town Marina. The town of Greenwich owned and
              controlled the parking lot and boat ramp, so the case hinged on
              municipal liability, said plaintiff attorney Frank Bartlett Jr.,
              founder of Bartlett & Grippe.
            </p>
            <p className="mb-8">
              The town argued the only remedy was for the plaintiff to sue under
              the state’s highway defects statute, under Section 13a-149, which
              governs damages for injuries from defective roads and bridges.
              Bartlett said his team investigated the history of the marina and
              learned that in the 1960s and 1970s, the marina had barriers that
              would have prevented the plaintiff decedent Patrick Conroy from
              going into the water. An amended lawsuit was filed Aug. 6. The
              incident at the boat ramp occurred in September 2015.
            </p>
            <p className="mb-8">
              “We had to be creative because of municipal liability,” Bartlett
              said. “Because this marina was adjacent to the roadway, the town
              argued the sole remedy available for us was the highway defects
              statute. But it’s difficult to win under that statute because you
              have to show the dangerous condition was the sole proximate cause
              of the injury sustained. If the plaintiff is even 1% negligent,
              then the claim fails.” It’s not clear why Conroy, who was
              attempting to negotiate a turn, drove into the water.
            </p>
            <p className="mb-8">
              Bartlett said he rejected using the statute as a defense and
              instead learned, through his investigation, of the barriers that
              were in place at the marina in the 1960s and 1970s. Bartlett said
              he would have argued to a jury that Greenwich was negligent in
              removing those barriers decades ago.
            </p>
            <p className="mb-8">
              “The sole issue for us was going to be whether or not the marina
              constituted a nuisance, and whether the town’s act in removing the
              barriers contributed to this accident,” Bartlett said Monday.
            </p>
            <p className="mb-8">
              The case settled Dec. 5. Jury selection was slated for Dec. 18.
            </p>
            <p className="mb-8">
              Greenwich Assistant Town Attorney Aamina Ahmad told the
              Connecticut Law Tribune Monday that the town is considering
              placing barriers at the site. She also said that “over the course
              of the litigation, both sides spent a lot of money defending the
              case.”
            </p>
            <p className="mb-8">
              Noting that Bartlett originally filed a $6.5 million offer of
              compromise, Ahmad said, “There was a significant reduction from
              what they first asked for to where we ended up. I think the
              plaintiff had started out with a much higher expectation. The town
              takes these cases very seriously and fully vets them.”
            </p>
            <p className="mb-8">
              The $600,000 settlement, Bartlett said, will be divided. He said
              $180,000 will go to the estate, while $420,000 will go to the
              plaintiff’s family members who were at the marina and witnessed
              the drowning. These witnesses included Conroy’s son, John, John
              Conroy’s wife and four of Patrick Conroy’s grandchildren.
            </p>
            <p className="mb-8">
              The family, Bartlett said, had just dropped their boat off at the
              marina and Patrick Conroy was attempting to bring the family
              vehicle to the parking area. Patrick Conroy was trapped in his
              vehicle and drowned, Bartlett said. In court papers, the town
              argued that Conroy was partly responsible for his own death in
              that he “failed to be watchful of his surroundings, failed to
              exercise reasonable care for his safety in the manner in which he
              was operating his vehicle.”
            </p>
            <p className="mb-8">Courtesy of the Connecticut Law Tribune</p>
          </div>
          <div className="mb-16">
            <h3>
              $1.2M Settlement for Hartford Man Who Lay Undiscovered for 8 Hours
              After Fall
            </h3>
            <p className="mb-8">
              <strong>
                Cheshire attorney Frank Bartlett Jr. has secured a $1.2 million
                settlement for Hartford resident Tommie Buchanon, who was left a
                quadriplegic following a fall on construction debris on the
                stairs of an apartment complex.
              </strong>
            </p>
            <p className="mb-8">
              By <strong>Robert Storace</strong> | August 12, 2019
            </p>
            <div className="mb-8 text-left">
              <StaticImage
                quality="100"
                width="478"
                src="../../images/hartford-courthouse-300x158-1.png"
                formats={["auto", "webp", "avif"]}
                alt="Photo of Hartford Superior Court"
              />
            </div>

            <p className="mb-8">
              <strong>Hartford Superior Court</strong>
            </p>
            <p className="mb-8">
              A Cheshire attorney has secured a $1.2 million settlement for a
              62-year-old man who became a quadriplegic after slipping and
              falling on an accumulation of construction debris on a stairway.
              Attorney Frank Bartlett Jr. reached the settlement with three
              defendants in April, and the Probate Court approved the deal Aug.
              6.
            </p>
            <p className="mb-8">
              According to Bartlett, owner of Bartlett & Grippe, and the second
              amended complaint, plaintiff Tommie Buchanon was on a stairwell at
              a small apartment complex on Collins Street in Hartford in
              November 2014 when the incident occurred.
            </p>
            <p className="mb-8">
              Bartlett said Buchanon, a Hartford resident who was visiting his
              cousin at the complex, “slipped and fell backwards on the debris
              on the stairs. He stood up and attempted to grab the railing, but
              there was a curve in the railing that put the railing right up
              against the wall where he could not grasp it.” Buchanon then fell
              forward about 12 steps before striking his forehead and left
              shoulder, his attorney told the Connecticut Law Tribune on Monday.
            </p>
            <p className="mb-8">
              Buchanon was lying on the ground for eight hours until 2 a.m. when
              a resident found him. He was rushed to the hospital in an attempt
              to repair the spinal cord injury to his neck, Bartlett said.
            </p>
            <p className="mb-8">
              At issue during the two mediation sessions was how much alcohol
              Buchanon had consumed prior to his fall. Test results showed
              Buchanon’s blood alcohol level when he was found was 0.015, which
              is less than the 0.08 legal limit in Connecticut. But defense
              attorneys argued it would have been higher eight hours earlier,
              suggesting the plaintiff was legally drunk at the time of the
              fall. They placed it at between 0.12 and 0.25 at the time of the
              fall.
            </p>
            <p className="mb-8">
              The plaintiff’s side disagreed, presenting witnesses who said
              Buchanon had consumed one to two beers prior to the incident, and
              there was no evidence he was drunk.
            </p>
            <p className="mb-8">
              “The toxicologist retained by us said that his blood alcohol
              levels were so low that it could not be calculated at a reasonable
              degree of accuracy what his blood alcohol level was when he fell,”
              Bartlett said.
            </p>
            <p className="mb-8">
              Counsel for the property manager, owner and contractor raised
              several other arguments, including claims Buchanon had failed to
              keep a proper lookout, had not watched where he was stepping, and
              had worn improper footwear. In the end, plaintiffs counsel called
              the settlement “a fair resolution,” and said his team set up a
              trust for Buchanon with the settlement funds.
            </p>
            <p className="mb-8">
              Bartlett said Buchanon’s medical expenses to date are about
              $710,000, with future life-care costs expected to be between $2
              million and $3 million.
            </p>
            <p className="mb-8">
              Of the $1.2 million settlement, $500,000 came from State Farm
              Insurance, the carrier for defendant J & A Equities LLC, which was
              the property owner. State Farm also paid an additional $300,000
              for an another client, defendant North Hills Equities Inc., which
              managed the property. Another carrier, National Grange Mutual
              Insurance Co., paid $400,000 for its client, defendant R & F
              Construction LLC, the contractor that the city of Hartford had
              hired to clean the apartment building.
            </p>
            <p className="mb-8">
              Hartford was not a defendant, because plaintiffs counsel predicted
              sovereign immunity laws would shield it from liability.
            </p>
            <p className="mb-8">
              Three other defendants—sister companies Imagineers LLC, Imagineers
              Property Management LLC and Imagineers Holdings LLC—reached a
              confidential settlement with Buchanon. The city of Hartford
              retained the entities to inspect the property prior to it being
              accepted for Section 8 housing.
            </p>
            <p className="mb-8">
              J & A Equities and North Hill Equities’ attorney, Brian Frank of
              Penino & Moynihan in Danbury, declined to comment. And R & F
              Construction’s counsel, Sarah Christie of Hassett & Donnelly in
              Hartford, did not respond to a request for comment.
            </p>
          </div>
          <div className="mb-16">
            <h3>
              Family of Boy Abused by Branford Businessman Settles for
              $1,500,000
            </h3>
            <p className="mb-8">
              <strong>
                Published 8:43 pm EST, Monday, February 25, 2019. Greenwich
                Time, New Haven Register, CT Law Tribune.
              </strong>
            </p>
            <p className="mb-8">
              “BRANFORD — The family of a young boy sexually assaulted by the
              former owner of a Branford graphics company over a two-year period
              has reached a $1.5 million settlement with the perpetrator,
              according to the Connecticut Law Tribune.” “The family’s attorney,
              Frank Bartlett of Cheshire-based Bartlett & Grippe, told the Law
              Tribune that the boy’s father worked for Guay at K&G Graphics and
              the boy, who was 8 years old when the abuse began in 2014, often
              would accompany his father to work.”
            </p>
            <p className="mb-8">
              <a
                href="https://www.greenwichtime.com/metro/article/Family-of-boy-abused-by-former-Branford-13644369.php"
                target="_blank"
                rel="noreferrer"
              >
                The entire article can be found here
              </a>
            </p>
          </div>
          <div className="mb-16">
            <h3>
              Judge says wrongful death suit can proceed even if baby isn’t
              viable
            </h3>
            <p className="mb-8">
              <strong>
                Respected Wrongful Death and Personal Injury Attorney Frank
                Bartlett quoted concerning Status of Fetus at Issue in Wrongful
                Death Case.
              </strong>
            </p>
            <p className="mb-8">
              Michelle Tuccitto Sullo, The Connecticut Law Tribune August 28,
              2015
            </p>
            <p className="mb-8">
              Frank Bartlett of Bartlett & Grippe in Cheshire, who was not
              involved in this case but has handled many other personal injury
              cases, called Kamp’s ruling a “significant and well-reasoned”
              decision that allows a plaintiff to move forward in a case in
              which a child was born and later died. He said the law in this
              area is likely to change. “While in this case viability was not
              the determining factor because the child was born as opposed to
              dying in utero, medical advances are changing the line of
              viability,” Bartlett said, noting there have been rare cases in
              which children born before 22 weeks have survived.
            </p>
            <p className="mb-8">
              <a
                href="https://www.law.com/ctlawtribune"
                target="_blank"
                rel="noreferrer"
              >
                The entire article can be found here
              </a>
            </p>
          </div>
          <div className="mb-16">
            <h3>
              10/1/2014 Attorney Bartlett selected to CT Law Tribune’s “2014 New
              Leaders in the Law”.
            </h3>
          </div>
          <div className="mb-16">
            <h3>
              6/27/2014 CT Law Tribune -Bartlett wins $2,750,000 verdict in an
              adult victim of childhood sexual abuse case
            </h3>
          </div>
          <div className="mb-16">
            <h3>
              9/20/2013 Below is TV coverage of Attorney Frank Bartlett before
              the CT Supreme Court in Wood v. Club LLC.
            </h3>
          </div>
          <div className="mb-16">
            <h3>
              06/15/2012 Attorney Bartlett quoted in Los Angeles Times about
              sexual abuse cases against Boy Scouts of America
            </h3>
          </div>
          <div className="mb-16">
            <h3>
              02/22/2012 WVIT 30 Boy Scout Molestation Case brought on behalf of
              clients by Frank Bartlett
            </h3>
          </div>
          <div className="mb-16">
            <h3>
              02/21/2012 News conference at the Omni Hotel in New Haven. Covered
              by local TV stations
            </h3>
            <p className="mb-8">
              Attorney Frank Bartlett of Cheshire and Kelly Clark of Portland,
              Oregon announce a lawsuit against the Boy Scouts of America and
              the Connecticut Yankee Council of the Boy Scouts saying they knew
              or should have known that convicted abuser David “Dirk” Davenport
              had molested boys in Montana and Nebraska prior to his activities
              in Connecticut.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
